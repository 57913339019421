import { goOrangeAxios } from './axios';

export const getDriversWithStatus = async function (tspId,status, params) {
    return goOrangeAxios.get(`/v1/mydriver-supply/tsp/${tspId}/drivers?approval_status=${status}`, { params });
};

export const getDrivers = async function (tspId, params) {
    return goOrangeAxios.get(`/v1/mydriver-supply/tsp/${tspId}/drivers`, { params });
};

export const getDriversByExt = async function (extId) {
    return goOrangeAxios.get(`v1/mydriver-supply/driver/${extId}`);
};
export const addDriver = async function (tspId, params) {
    return goOrangeAxios.post(`/v1/mydriver-supply/drivers`, 
    {     ...params,
            tspId
            
    });
};

export const updateDriver = async function (extId,tspId, params) {
    // / v1/mydriver-supply/driver/<driver_ext_id>
    return goOrangeAxios.put(`v1/mydriver-supply/drivers/${extId}`, 
    {     ...params,
            tspId
            
    });
};

export const getRejectionReasons = async function (params) {
    return goOrangeAxios.post(`v1/mydriver-supply/documents/rejection-reason`, {...params}); 
};

export const getRejectionReasonComment = async function (params) {
    return goOrangeAxios.post(`v1/mydriver-supply/documents/rejection-comments`,{...params});
};

