import { getData, getSessionData, getTomorrowDateFormatted, useQuery } from 'helpers';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import * as S from './TspContextProvider.styled';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RevGuardRegType, TspRoute, VerificationStatus } from 'enums';
import { useTsp } from 'hooks';
import { getNewBankingSchema } from 'services/tsp';
import { useCountryContext } from 'contexts/CountryContextProvider';

const TspContext = createContext();

export const TspContextProvider = ({ isAdmin, children }) => {
  const query = useQuery();
  const history = useHistory();

  const { checkIfTspIsInEu, getTspData, isCompanyProfilePage } = useTsp();
  const { selectedCountry } = useCountryContext();
  const { t } = useTranslation();

  const [tspExtId, setTspExtId] = useState(null);
  const [tspData, setTspData] = useState(null);
  const [bankingFields, setBankingFields] = useState(null);
  const [bankingSchema, setBankingSchema] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  const [isTspInEuCountry, setIsTspInEuCountry] = useState(false);
  const [isRevGuardDetailsRequired, setIsRevGuardDetailsRequired] = useState(false);
  const [tspRegType, setTspRegType] = useState(RevGuardRegType.LegalEntity);
  const [mandatoryBankingFields, setMandatoryBankingFields] = useState({});

  useEffect(() => {
    const currentTspExtId =
      query.get('ext_id') || getSessionData('admin-access-tspExtId') || getData('USER')?.tsp_ext_id || null;
    setTspExtId(currentTspExtId);
  }, [query]);

  const fetchTspData = useCallback(async () => {
    if (!tspExtId) {
      return;
    }

    const result = await getTspData(tspExtId);
    setTspData(result);
    setCountryCode(result.address.countryCode.toLowerCase());

    if (result.taxRegistrationType) {
      setTspRegType(result.taxRegistrationType);
    }
  }, [getTspData, tspExtId]);

  const isFieldValidForDAC7 = ({ dataFromForm, dataFromDB, isVerified, isMandatory }) => {
    return !isMandatory || (!!dataFromForm && (dataFromDB === dataFromForm || isVerified));
  };

  const validateVatId = (dataFromForm, vatIdVerificationStatus) => isFieldValidForDAC7({
    dataFromForm,
    dataFromDB: bankingFields?.vatId,
    isVerified: vatIdVerificationStatus === VerificationStatus.SUCCESS,
    isMandatory: mandatoryBankingFields?.vatId,
  });

  const validateTaxId = (dataFromForm, taxIdVerificationStatus) => isFieldValidForDAC7({
    dataFromForm,
    dataFromDB: bankingFields?.taxId,
    isVerified: taxIdVerificationStatus === VerificationStatus.SUCCESS,
    isMandatory: mandatoryBankingFields?.taxId,
  });

  useEffect(() => {
    const fetchBankingSchema = async () => {
      if ((tspData?.address?.countryCode && tspData?.bankingFields) || selectedCountry) {
        const schema = await getNewBankingSchema(tspData?.address?.countryCode || selectedCountry?.countryCode);
        setBankingSchema(schema.data.bankingSchema);

        if (tspData?.bankingFields) {
          const parsedBankingFields = JSON.parse(tspData?.bankingFields);

          const finalFields = {};
          const mandatoryFields = {};

          schema.data.bankingSchema.forEach(d => {
            finalFields[d.bankingFieldVarName] = parsedBankingFields?.[d.bankingFieldVarName];

            if (d.isRequired) {
              mandatoryFields[d.bankingFieldVarName] = true;
            }
          });
          setMandatoryBankingFields(mandatoryFields);
          setBankingFields(finalFields);
        }
      }
    };
    fetchBankingSchema();
  }, [tspData?.address?.countryCode, tspData?.bankingFields, selectedCountry]);

  useEffect(() => {
    if (!tspData?.address?.countryCode) {
      return;
    }
    setCountryCode(tspData?.address?.countryCode?.toLowerCase());
    setIsTspInEuCountry(checkIfTspIsInEu(tspData.address.countryCode));
  }, [checkIfTspIsInEu, tspData?.address?.countryCode]);

  useEffect(() => {
      setIsRevGuardDetailsRequired(isTspInEuCountry && !tspData?.taxRegistrationType);
  }, [isTspInEuCountry, tspData]);

  const getTspRegTypeLabel = useCallback(
    (regType) => {
      if (regType === RevGuardRegType.LegalEntity) {
        return t('DAC7_REG_TYPE_MODAL_LEGAL_ENTITY_TITLE');
      }
      return t('DAC7_REG_TYPE_MODAL_NATURAL_PERSON_TITLE');
    },
    [t]
  );

  const isDac7BannerShown = !isCompanyProfilePage && !isAdmin && isRevGuardDetailsRequired;

  return (
    <TspContext.Provider
      value={{
        tspData,
        isRevGuardDetailsRequired,
        bankingFields,
        setBankingFields,
        countryCode,
        tspRegType,
        setTspRegType,
        fetchTspData,
        bankingSchema,
        getTspRegTypeLabel,
        vatIdData: {
          validateVatId,
          isVatIdMandatory: mandatoryBankingFields.vatId,
          name: bankingSchema?.find(d => d.bankingFieldVarName === 'vatId')
        },
        taxIdData: {
          validateTaxId,
          isTaxIdMandatory: mandatoryBankingFields.taxId,
          name: bankingSchema?.find(d => d.bankingFieldVarName === 'taxId')
        },
        isFieldValidForDAC7,
        setIsRevGuardDetailsRequired,
        setTspData
      }}>
      <>
        {isDac7BannerShown && (
          <S.Dac7Banner
            headerText={`${t('DAC7_BANNER_HEADER')}!`}
            text={t('DAC7_BANNER_TEXT', { date: getTomorrowDateFormatted() })}
            buttonText={t('DAC7_BANNER_BUTTON')}
            isSticky
            isHeaderCentered={false}
            onButtonClick={() => history.push(TspRoute.CompanyProfile)}
          />
        )}
        {children}
      </>
    </TspContext.Provider>
  );
};

/**
 * @typedef {'legalEntity' | 'naturalPerson'} RevGuardRegType
 */

/**
 * @typedef {Object} TspContext
 * @property {Object} tspData
 * @property {boolean} isRevGuardDetailsRequired
 * @property {RevGuardRegType} tspRegType
 * @property {(type: RevGuardRegType) => void} setTspRegType
 * @property {() => Promise<void>} fetchTspData
 * @property {Object} vatIdData
 * @property {Object} taxIdData
 * @property {(required: RevGuardRegType) => void} setIsRevGuardDetailsRequired
 */

/**
 * @returns {TspContext} TspContext
 */
export const useTspContext = () => {
  const context = useContext(TspContext);

  if (!context) {
    throw new Error('useTspContext must be used within a TspContextProvider');
  }

  return context;
};
